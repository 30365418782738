import React from "react";
import Logo from "../assets/wideLogoDark.png";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  let navigate = useNavigate();
  return (
    <div
      style={{
        width: "100%",
        height: 80,
        background: "#10151c",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img
          src={Logo}
          style={{ width: "auto", height: 50, cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 30,
          }}
        >
          <p
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => navigate("/privacy")}
          >
            PRIVACY POLICY
          </p>
          <p
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => navigate("/tos")}
          >
            TERMS OF SERVICE
          </p>
          <p
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => navigate("/eula")}
          >
            EULA
          </p>
          <p
            style={{
              padding: "15px 35px",
              borderRadius: 5,
              background: "#f20c2e",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => (window.location.href = "https://hyperauth.app")}
          >
            HYPERAUTH WEBSITE
          </p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
