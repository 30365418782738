import Navbar from "./navbar";
import React from "react";
import moment from "moment";

const Home = () => {
  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        background: "#eaf4f4",
      }}
    >
      <Navbar />
      <div
        style={{
          marginTop: 60,
          marginBottom: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "80%",
          alignItems: "center",
          height: "100%",
          flexGrow: 1,
        }}
      >
        <div style={{ width: "100%", flexGrow: 1 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{ fontSize: 72, marginBottom: 10 }}>HyperAuth</h1>
          <h4 style={{ fontWeight: 400, fontSize: 18 }}>
            Multi-Factor Authentication -{" "}
            <span style={{ fontWeight: 700 }}>SIMPLIFIED</span>
          </h4>
        </div>
        <div style={{ width: "100%", flexGrow: 1 }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: 30,
            alignItems: "center",
            width: "80%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              gap: 30,
              alignItems: "center",
              width: "80%",
            }}
            className="footer-content"
          >
            <p style={{ cursor: "pointer" }}>Privacy Policy</p>
            <div style={{ width: 1, height: 20, background: "#10151c" }} />
            <p style={{ cursor: "pointer" }}>Terms of Service</p>
            <div style={{ width: 1, height: 20, background: "#10151c" }} />
            <p style={{ cursor: "pointer" }}>End-User License Agreement</p>
          </div>
          <p
            style={{ color: "#3d4044", fontSize: 14 }}
            className="footerCopyright"
          >
            ALL RIGHTS &copy; HyperAuth {moment().format("YYYY")}, a{" "}
            <a
              href="https://liveuptothehype.com/?ref=hyperAuthLegal"
              target="_blank"
            >
              Hypertek Solutions
            </a>{" "}
            product
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
