import { Routes, Route } from "react-router-dom";
import Privacy from "./pages/privacy";
import Eula from "./pages/eula";
import TOS from "./pages/tos";
import Home from "./pages/home";

function App() {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Routes>
        <Route index element={<Home />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="tos" element={<TOS />} />
        <Route path="eula" element={<Eula />} />
      </Routes>
    </div>
  );
}

export default App;
